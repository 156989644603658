import { useAuthContext } from '../../hooks/useAuthContext';
import './FormInput.css'
import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const PanneSelect = (props) => {
  const handleChange = (selectedOption) => {
    if (selectedOption === null) {
      // Handle clearable button click
      if (props.onChange) {
        props.onChange(null);
      }
    } else {
      // Handle regular selection
      if (props.onChange) {
        props.onChange(selectedOption.value);
      }
    }
  };
  const { user } = useAuthContext();
  const [TypePanne, setTypePanne] = useState([]);
  useEffect(() => {
    const fetchTypePanneData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+'/TypePanne', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setTypePanne(data.TypePannes);
        } else {
          console.error("Error receiving Panne data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Panne data:", error);
      }
    };
  
    fetchTypePanneData();
  }, [user?.token]);
  return (
    <div className='forminput'>
      <label>{props.label}</label>
      <Select
        className="select-custom"
        classNamePrefix="select"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 10,
            borderColor: state.isFocused ? '#000' : 'grey',
            textAlign: 'center',
            fontSize: '1em',
            display: 'flex',
            backgroundColor: '#dfdcdc',
            marginTop: 5,
          }),
        }}
        placeholder = "Sélectionné une panne"
        isClearable={true}
        isSearchable={true}
        options={TypePanne?.map(option => ({
          value: option.Nom,
          label: option.Nom
        }))}
        onChange={handleChange}
      />
    </div>
    
  )
}

export default PanneSelect
