import React, { useEffect } from 'react'
import MyAsideBarActive from '../Components/asideBarActive'
import { useState} from "react";
import MyAsideBar from "../Components/asideBar";
import MyNavBar from "../Components/navBar";
import { useAuthContext } from '../hooks/useAuthContext';
import './Style/PanneList.css'
import { CircularProgress } from '@mui/material';
import TableSAVRow from '../Components/Table/TableSAVRow';
import { FaPlus } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableTypePanneRow from '../Components/Table/TableTypePanneRow';
import WilayaSelect from '../Components/Form/WilayaSelect';

const SAV = () => {
    const notifyFailed = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);
    const [act, setAct] = useState(false);
    const [search, setSearch] = useState("");
    const [ProductsData, setProductsData] = useState([]);
    const { user } = useAuthContext();
    const [Region, setRegion] = useState("");
    const [Wilaya, setWilaya] = useState("");
    const [Telephone, setTelephone] = useState("");
    const [Localisation, setLocalisation] = useState("");
    const [searchWilaya, setSearchWilaya] = useState("");
    const [openDialog1, setOpenDialog1] = useState(false);

    const fetchPannesData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+`/SAV`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setProductsData(data);
        } else {
          console.error("Error receiving SAV data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching SAV data:", error);
      }
    };
    useEffect(() => {    
      fetchPannesData();
    }, [user?.Centre, user?.Role, user?.id, user?.token, Region, Wilaya]);
    const handleCloseDialog = () => {
      setOpenDialog1(false);
    }
    const handleAddNewProduct = async () => {
      try {
        if(Region === "" || Wilaya === ""){
          notifyFailed("Le nom et la wilaya du SAV sont requis")
          return;
        }
        const response = await fetch(process.env.REACT_APP_URL_BASE+`/SAV/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
            Region : Region, 
            Wilaya : Wilaya, 
            Telephone : Telephone, 
            Localisation : Localisation
          })
        });
        const data = await response.json();
        if (response.ok) {
            setOpenDialog1(false);
            notifySuccess(data.message);
            setRegion("");
            setWilaya("");
            setTelephone("");
            setLocalisation("");
        } else {
            setOpenDialog1(false);
            notifyFailed(data.message);
        }
      } catch (error) {
        console.error("Error creating SAV data:", error);
      }
    }
    const handleClickOpen = () => {
      setOpenDialog1(true);
    }
    const handleWilayaInputChange = (newValue) => {
      setSearchWilaya(newValue);
    };
    const handleWilayaformInputChange = (newValue) => {
      setWilaya(newValue);
    };
    return (
    <>
    <MyNavBar  act={act} setAct={setAct} />
      <MyAsideBar />
    <div className="Patients-Details">
      <div className="patient-table">
        <MyAsideBarActive act={act} setAct={setAct}></MyAsideBarActive>
        <div className="patient-table-container">

            <div className="patient-table-header">
                <div className="table-header-item">
                  <WilayaSelect label='Wilaya :' placeholder='Entrer la willaya' onChange={handleWilayaInputChange}/>
                </div>
                <div className="table-header-item">
                    <label>Recherche</label>
                    <input
                        type="search"
                        className="class-search"
                        placeholder="Search.."
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="table-header-item">
                    <div className='add-user-btn'>
                        <div className="Add-btn-class" onClick={handleClickOpen}>
                            <FaPlus size={15} fill="#fff" />
                            <input type="button" value="Ajouter une panne" className="voir-btn" />
                        </div>
                    </div>
                </div>
            </div>
            {ProductsData && ProductsData !== undefined ?
                <div className="table-patients">
                    <table>
                    <tr className="table-patients-header">
                        <td className="table-patients-header-phone" colSpan={3} >Nom</td>
                        <td className="table-patients-header-phone" colSpan={3} >Wilaya</td>
                        <td className="table-patients-header-nom"></td>
                    </tr>
                    {ProductsData.length < 1 ? (
                        <td colSpan={3}>
                        <div style={{ margin: '20px 0', textAlign: 'center' }}>
                            <h1>Aucun SAV disponible</h1>
                        </div>                
                        </td>
                        ) : (
                        ProductsData?.slice(0).reverse().filter(item => {
                          if((
                              search.toLowerCase() === "" ||
                              item.Region.toLowerCase().includes(search.toLowerCase()) ||
                              (item.Wilaya != null && item.Wilaya.toLowerCase().includes(search.toLowerCase()))
                            )
                            &&
                            (
                              searchWilaya === "All" || (item.Wilaya?.toLowerCase().includes(searchWilaya.toLowerCase()))
                            )
                          ){
                            return item;
                          }
                          return null;
                        }).map(sav => (
                            <TableSAVRow key={sav.id} SAV={sav} fetchSAVData={fetchPannesData}/>
                        )
                        )
                    )
                    }
                    </table>
                </div>
            :
                <div className="CircularProgress-container">
                    <CircularProgress className='CircularProgress' />
                </div>
            }
        </div>
      </div>
      <ToastContainer/>
      <Dialog
          open={openDialog1}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Ajouter un SAV`}</DialogTitle>
          <DialogContent>
            <div className="patient-table-header">
                <div className="table-header-item">
                  <WilayaSelect label='Wilaya :' placeholder='Entrer la willaya' onChange={handleWilayaformInputChange}/>
                </div>
            </div>
            <DialogContentText id="alert-dialog-description">
              Le nom du SAV :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Nom du SAV"
                onChange={(e) => setRegion(e.target.value)}
            />
            <DialogContentText id="alert-dialog-description">
              Le numero de telephone du sav :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Numero de telephone du sav"
                onChange={(e) => setTelephone(e.target.value)}
            />
            <DialogContentText id="alert-dialog-description">
              La localisation du sav :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Localisation du sav"
                onChange={(e) => setLocalisation(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleAddNewProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
      </Dialog>
    </div>
    </>
  )
}
export default SAV
