import React, { useEffect } from 'react'
import MyAsideBarActive from '../Components/asideBarActive'
import { useState} from "react";
import MyAsideBar from "../Components/asideBar";
import MyNavBar from "../Components/navBar";
import { useAuthContext } from '../hooks/useAuthContext';
import './Style/PanneList.css'
import { CircularProgress } from '@mui/material';
import TableProductRow from '../Components/Table/TableProductRow';
import { FaPlus } from 'react-icons/fa';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableTypePanneRow from '../Components/Table/TableTypePanneRow';

const TypePanne = () => {
    const notifyFailed = (message) => toast.error(message);
    const notifySuccess = (message) => toast.success(message);
    const [act, setAct] = useState(false);
    const [search, setSearch] = useState("");
    const [ProductsData, setProductsData] = useState([]);
    const { user } = useAuthContext();
    const [RefProduit, setRefProduit] = useState("");
    const [openDialog1, setOpenDialog1] = useState(false);

    const fetchPannesData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+`/TypePanne`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setProductsData(data.TypePannes);
        } else {
          console.error("Error receiving Type de panne data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Type de panne data:", error);
      }
    };
    useEffect(() => {    
      fetchPannesData();
    }, [user?.Centre, user?.Role, user?.id, user?.token, RefProduit]);
    const handleCloseDialog = () => {
      setOpenDialog1(false);
    }
    const handleAddNewProduct = async () => {
      try {
        if(RefProduit === ""){
          notifyFailed("Veuillez remplir le champ de référence")
          return;
        }
        const response = await fetch(process.env.REACT_APP_URL_BASE+`/Typepanne/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
          body: JSON.stringify({
              nom: RefProduit
          })
        });
        const data = await response.json();
        if (response.ok) {
          setOpenDialog1(false);
          notifySuccess(data.message);
          setRefProduit("");
        } else {
          setOpenDialog1(false);
          notifyFailed(data.message);
        }
      } catch (error) {
        console.error("Error creating Type panne data:", error);
      }
    }
    const handleClickOpen = () => {
      setOpenDialog1(true);
    }
    return (
    <>
    <MyNavBar  act={act} setAct={setAct} />
      <MyAsideBar />
    <div className="Patients-Details">
      <div className="patient-table">
        <MyAsideBarActive act={act} setAct={setAct}></MyAsideBarActive>
        <div className="patient-table-container">

            <div className="patient-table-header">
                <div className="table-header-item">
                    <label>Recherche</label>
                    <input
                        type="search"
                        className="class-search"
                        placeholder="Search.."
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="table-header-item">
                    <div className='add-user-btn'>
                        <div className="Add-btn-class" onClick={handleClickOpen}>
                            <FaPlus size={15} fill="#fff" />
                            <input type="button" value="Ajouter une panne" className="voir-btn" />
                        </div>
                    </div>
                </div>
            </div>
            {ProductsData && ProductsData !== undefined ?
                <div className="table-patients">
                    <table>
                    <tr className="table-patients-header">
                        <td className="table-patients-header-phone" colSpan={3} >Nom</td>
                        <td className="table-patients-header-nom"></td>
                    </tr>
                    {ProductsData.length < 1 ? (
                        <td colSpan={3}>
                        <div style={{ margin: '20px 0', textAlign: 'center' }}>
                            <h1>Aucune panne disponible</h1>
                        </div>                
                        </td>
                        ) : (
                        ProductsData?.slice(0).reverse().filter(item => {
                          if((
                              search.toLowerCase() === "" ||
                              item.Nom.toLowerCase().includes(search.toLowerCase())
                            )
                          ){
                            return item;
                          }
                          return null;
                      }).map(panne => (
                            <TableTypePanneRow key={panne.id} Panne={panne} fetchTypePanneData={fetchPannesData}/>
                        )
                        )
                    )
                    }
                    </table>
                </div>
            :
                <div className="CircularProgress-container">
                    <CircularProgress className='CircularProgress' />
                </div>
            }
        </div>
      </div>
      <ToastContainer/>
      <Dialog
          open={openDialog1}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Ajouter une panne`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Entrez une panne :
            </DialogContentText>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            height="100px"
            variant="standard"
            placeholder="Nom de la panne"
            onChange={(e) => setRefProduit(e.target.value)}
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleAddNewProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
      </Dialog>
    </div>
    </>
  )
}
export default TypePanne
