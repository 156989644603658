import React, { useEffect, useMemo } from 'react'
import VoirButton from '../Buttons/buttonVoir';
import moment from 'moment-timezone';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import Deletebutton from '../Buttons/deletebutton';
import Updatebutton from '../Buttons/updatebutton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TableTypePanneRow ({Panne, fetchTypePanneData}){
  const notifyFailed = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);
  const { user } = useAuthContext();
  const [openDialogSuspension, setOpenDialogSuspension] = useState(false);
  const [openDialogUpdate, setopenDialogUpdate] = useState(false);
  const [RefProduit, setRefProduit] = useState("");
  const handleCloseDialog = () => {
    setOpenDialogSuspension(false);
    setopenDialogUpdate(false);
  }
  const handleDeleteProduct = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_URL_BASE+`/TypePanne/delete/${Panne?.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setOpenDialogSuspension(false);
        fetchTypePanneData();
        notifySuccess(data.message);
      } else {
        setOpenDialogSuspension(false);
        notifyFailed(data.message);
      }
    } catch (error) {
      console.error("Error creating Panne data:", error);
    }
  }
  const handleUpdateProduct = async () => {
    try {
      if(RefProduit === ""){
        notifyFailed("Veuillez remplir le champ de référence")
        return;
      }
      const response = await fetch(process.env.REACT_APP_URL_BASE+`/TypePanne/update/${Panne?.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
        body:JSON.stringify({
          nom: RefProduit
      })
      });
      const data = await response.json();
      if (response.ok) {
        setopenDialogUpdate(false);
        fetchTypePanneData();
        notifySuccess(data.message);
        setRefProduit("");
      } else {
        setopenDialogUpdate(false);
        notifyFailed(data.message);
      }
    } catch (error) {
      console.error("Error creating Panne data:", error);
    }
  }
  const handleClickOpenSuspension = () => {
    setOpenDialogSuspension(true);
  }
  const handleClickOpenUpdate = () => {
    setopenDialogUpdate(true);
  }
  return (
    <tr>
      <td className="table-patients-td-phone" colSpan={3}>{Panne?.Nom}</td>
      <td className="table-patients-td table-patient-td-nom buttonsProducts" >
        <Updatebutton Redirection={handleClickOpenUpdate}/>
        <Deletebutton DeleteUser={handleClickOpenSuspension}/>
      </td>
      <ToastContainer/>
      <Dialog
          open={openDialogSuspension}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Confirmer la suspension de ce panne`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {Panne?.Nom}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleDeleteProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogUpdate}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Modifie ce produit " ${Panne?.Nom} "`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              La panne actuelle : {Panne?.Nom}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              La nouvelle Panne :
            </DialogContentText>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            height="100px"
            variant="standard"
            placeholder="Nom de la panne"
            onChange={(e) => setRefProduit(e.target.value)}
          />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleUpdateProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
    </tr>
  )
}

export default TableTypePanneRow