import React, { useEffect, useMemo } from 'react'
import VoirButton from '../Buttons/buttonVoir';
import moment from 'moment-timezone';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import Deletebutton from '../Buttons/deletebutton';
import Updatebutton from '../Buttons/updatebutton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WilayaSelect from '../Form/WilayaSelect';

function TableAgentAgreeRow ({Agent, fetchAgentData}){
  const notifyFailed = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);
  const { user } = useAuthContext();
  const [openDialogSuspension, setOpenDialogSuspension] = useState(false);
  const [openDialogUpdate, setopenDialogUpdate] = useState(false);
  const [Region, setRegion] = useState("");
  const [Wilaya, setWilaya] = useState("");
  const [Telephone, setTelephone] = useState("");
  const [Nom, setNom] = useState("");
  const [Prenom, setPrenom] = useState("");
      const handleCloseDialog = () => {
        setOpenDialogSuspension(false);
        setopenDialogUpdate(false);
    }
  const handleDeleteProduct = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_URL_BASE+`/Agent/delete/${Agent?.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setOpenDialogSuspension(false);
        fetchAgentData();
        notifySuccess(data.message);
      } else {
        setOpenDialogSuspension(false);
        notifyFailed(data.message);
      }
    } catch (error) {
      console.error("Error deleting Agent data:", error);
    }
  }
  const handleUpdateProduct = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_URL_BASE+`/Agent/update/${Agent?.id}`, {
            method: "PATCH",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
            },
            body:JSON.stringify({
              Nom: Nom,
              Prenom: Prenom,
              Region : Region, 
              Wilaya : Wilaya, 
              Telephone : Telephone, 
            })
        });
        const data = await response.json();
        if (response.ok) {
            setopenDialogUpdate(false);
            fetchAgentData();
            notifySuccess(data.message);
            setRegion("");
            setWilaya("");
            setTelephone("");
            setNom("");
            setPrenom("");
        } else {
            setopenDialogUpdate(false);
            notifyFailed(data.message);
        }
    } catch (error) {
      console.error("Error updating Agent data:", error);
    }
  }
  const handleClickOpenSuspension = () => {
    setOpenDialogSuspension(true);
  }
  const handleClickOpenUpdate = () => {
    setopenDialogUpdate(true);
  }
  const handleWilayaformInputChange = (newValue) => {
    setWilaya(newValue);
  };
  return (
    <tr>
        <td className="table-patients-td-phone">{Agent?.Nom ? Agent?.Nom : "/"}</td>
        <td className="table-patients-td-phone">{Agent?.Prenom ? Agent?.Prenom : "/"}</td>
        <td className="table-patients-td-phone">{Agent?.Region}</td>
        <td className="table-patients-td-phone">{Agent?.Wilaya}</td>
        <td className="table-patients-td table-patient-td-nom buttonsProducts" >
            <Updatebutton Redirection={handleClickOpenUpdate}/>
            <Deletebutton DeleteUser={handleClickOpenSuspension}/>
        </td>
      <ToastContainer/>
      <Dialog
          open={openDialogSuspension}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Confirmer la suspension de ce Agent`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {Agent?.Region}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleDeleteProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogUpdate}
          onClose={false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Modifie ce Agent " ${Agent?.Region} "`}</DialogTitle>
          <DialogContent>
            <div className="patient-table-header">
                <div className="table-header-item">
                  <WilayaSelect label='Wilaya :' placeholder='Entrer la willaya' onChange={handleWilayaformInputChange}/>
                </div>
            </div>
            <DialogContentText id="alert-dialog-description">
              Local du Agent :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Local du Agent"
                onChange={(e) => setRegion(e.target.value)}
            />
            <DialogContentText id="alert-dialog-description">
              Le nom du Agent :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Nom du Agent"
                onChange={(e) => setNom(e.target.value)}
            />
            <DialogContentText id="alert-dialog-description">
              Le prenom du Agent :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Prenom du Agent"
                onChange={(e) => setPrenom(e.target.value)}
            />
            <DialogContentText id="alert-dialog-description">
              Le numero de telephone du Agent :
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                type="text"
                fullWidth
                height="100px"
                variant="standard"
                placeholder="Numero de telephone du Agent"
                onChange={(e) => setTelephone(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Annuler</Button>
            <Button onClick={handleUpdateProduct} autoFocus>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
    </tr>
  )
}

export default TableAgentAgreeRow